<template>
  <div class="cs-radio-buttons">
    <button
      v-for="(button, index) in buttons"
      :key="index"
      :disabled="'disabled' in button ? button.disabled : false"
      :class="{ 'cs-button--active': value === button.id }"
      @click="onClickButton(button.id)"
      class="cs-radio-buttons__cs-button cs-button"
    >
      {{ button.name }}
    </button>
  </div>
</template>

<script>
export default {
  name: 'RadioButtons',

  model: {
    prop: 'modelValue',
    event: 'update:modelValue',
  },

  props: {
    modelValue: {
      type: String || Number,
      default: null,
    },

    buttons: {
      type: Array,
      required: true,
      validator(value) {
        if (!value?.length) return false;
        if (
          !value.every((button) => {
            if (button?.id === null) return false;
            if (button?.id === undefined) return false;
            if (!button?.name) return false;
            return true;
          })
        ) {
          return false;
        }
        return true;
      },
    },
  },

  computed: {
    value: {
      get() {
        return this.modelValue;
      },

      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
  },

  created() {
    if (this.value) {
      const findedButton = this.buttons.find(
        (button) => button.id === this.value
      );

      if (findedButton) return;
    }

    this.value = this.buttons[0].id;
  },

  methods: {
    onClickButton(id) {
      this.value = id;
    },
  },
};
</script>

<style lang="scss">
.cs-radio-buttons {
  display: flex;
  gap: 10px 20px;
  flex-wrap: wrap;

  & .cs-button {
    font-weight: 500;
    padding-bottom: 2px;
    cursor: pointer;
    user-select: none;

    &--active {
      border-bottom: 2px solid var(--dark-blue);
      padding-bottom: 0;
      color: var(--dark-blue);

      cursor: default;
    }

    &:disabled {
      opacity: 0.8;
      cursor: not-allowed;
    }
  }
}
</style>
